import { Routes,Route } from 'react-router-dom';
import { ErrorPage } from '../pages/error/ErrorPage';
import { Armory } from '../pages/armory/Armory';

export const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/:raiderId" element={<Armory />}/>
        <Route path="*" element={<ErrorPage />}/>
      </Routes>
    </>
  )
}
