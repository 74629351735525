import React from 'react'
import { Sprite } from './Sprite';

export const ArmoryCharacter = ({avatar}) => {

    const { body, dress, face, hair } = avatar;

    const bodyPartURL = (part) => `https://storage.googleapis.com/crypto-raiders-assets/char_new/${part}.png`;

    return (
        <div className='container'>
            <div className='character_panel'>
                <div className='character'>
                    <Sprite bgImage={bodyPartURL(`body/${body}`)}/>
                    <Sprite bgImage={bodyPartURL(`dress/${dress}`)}/>
                    <Sprite bgImage={bodyPartURL(`face/${face}`)}/>
                    <Sprite bgImage={bodyPartURL(`hair/${hair}`)}/>
                </div>
            </div>
        </div>
        
    )
}
