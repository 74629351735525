import { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ArmoryPanel } from '../../components/armory/ArmoryPanel';
import { getRaiderById } from '../../CrApi';


export const Armory = () => {

  const {raiderId} = useParams();
  const [raider,setRaider] = useState(null);

  useEffect(() => {

    (async () => {
      const {data} = await getRaiderById(raiderId);
      console.log(data);
      setRaider(data);
    })();

  },[raiderId]);

  return (
    <section className='flex flex-col justify-center items-center md:block  relative w-100 h-100'>
      {raider && <ArmoryPanel raider={raider}/>}
    </section>
  )
}
