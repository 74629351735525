import { Item } from "../inventory/Item";

export const Slots = ({slots,align}) => {

  return (
    <div className="slots">
        <div className="container">
            <div className="flex flex-row md:flex-col gap-4 justify-end">
                { slots.map((item,i) => <Item item={item} key={i} align={align}/> ) }
            </div>
            
        </div>
    </div>
  )
}
