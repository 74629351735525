import {useEffect} from 'react';
import {
  useFloating,
  shift,
  autoUpdate,
} from '@floating-ui/react-dom';

export const Item = ({item,align}) => {   

    const {x, y, reference, floating, strategy, update, refs} =
        useFloating({
        placement: 'middle',
        middleware: [shift()],
        });
    
    useEffect(() => {
        if (!refs.reference.current || !refs.floating.current) {
        return;
        }
    
        // Only call this when the floating element is rendered
        return autoUpdate(
        refs.reference.current,
        refs.floating.current,
        update
        );
    }, [refs.reference, refs.floating, update]);


    const { name, stats, internalName } = item.item;

    const iconURL = (icon) => `https://storage.googleapis.com/crypto-raiders-assets/icons/${icon}_ICON.png`;

    return (
        <>
            <div className={`flex ${align === 'left'? 'flex-row-reverse text-right' : 'flex-row text-left'} items-center`} ref={reference}>
                <div className="slot p-2 mx-3 w-20 h-20">
                    {internalName && <div style={{backgroundImage: `url(${iconURL(internalName)})`}} className="bg-contain bg-no-repeat bg-center w-16 h-16"/>}
                </div>
                { name && <div className='w-100 hidden md:block'>
                        <h2 className='text-base mb-1'>{name}</h2>
                        {stats !== undefined && <div className={`text-sm flex flex-row gap-1 ${align === 'left'? 'justify-end' : 'justify-start'}`}>{Object.keys(stats).map((stat,i) => <span key={i}>{stat}: {stats[stat]}</span>)}</div>}
                    </div> }
            </div>

            <div
                ref={floating}
                style={{
                position: strategy,
                top: y ?? '',
                left: x ?? '',
                }}
            >
                Tooltip
            </div>
        </>
    )
}
