import { Router } from './router/Router'

function App() {

  return (
    <main>
      <Router/>
    </main>
  );
}

export default App;
