export const ArmoryHeader = ({raider}) => {

    const { name,level,race } = raider;
    return (
        
        <header className="text-center py-5">
            <div className="container">
                <h1 className="text-2xl text-center">{name}</h1>
                <p className="text-yellow">Lvl {level} {race}</p>
            </div>
        </header>
    )
}
