
import { ArmoryCharacter } from "./ArmoryCharacter";
import { ArmoryHeader } from "./ArmoryHeader";
import { Slots } from "./Slots";


export const ArmoryPanel = ({raider}) => {

    const { inventory, avatar } = raider;
    const dress = inventory.find(slot => slot.item.slot === 'dress');
    avatar.dress = dress.item.internalName || null;

    const leftSlots = ['head','main_hand','dress'].map(slot => inventory.find(item => item.item.slot === slot) || {item: {}});
    const rightSlots = ['knickknack','finger','neck'].map(slot => inventory.find(item => item.item.slot === slot) || {item: {}});

    return (
        <>
            <article className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
                <div className="md:order-first">
                    <Slots slots={leftSlots} align="right"/>
                </div>
                <div className="order-first md:order-2">
                    <ArmoryCharacter avatar={ avatar }/>
                    <ArmoryHeader raider={ raider }/>
                </div>
                <div className="md:order-last ">
                    <Slots slots={rightSlots} align="left"/>
                </div>
            </article>
        </>
    )
}
